// material-ui
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

// third-party
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

// assets
import {
  IconChevronRight,
  IconChevronLeft,
  IconLink,
} from '@tabler/icons-react';
import SliderDark2 from '../../public/images/ResponsibleGaming.png';
import BannerSlider2 from '../../public/images/banner33.png';
import IND from '../../public/images/ind-flag.png';

// types
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import GameService from '@/services/GameService';
import { borderRadius } from 'styled-system';
import { MathFloor } from '@/utils/formatNumberFloor';

// styles
const Images = styled('img')(({ theme }) => ({
  marginBottom: 0,
  backgroundSize: '100%',
  objectFit: 'cover',
  [theme.breakpoints.down('sm')]: {
    backgroundSize: '100%',
    objectFit: 'fill',
  },
}));

const CarouselSlider = styled(Box)(({ theme }) => ({
  '.carousel-slider': {
    // height: '245px',
    [theme.breakpoints.down('sm')]: {
      // height: '145px',
    },
  },
  '.MuiButtonBase-root': {
    background: 'rgba(0,0,0,.2)',
    height: '100% !important',
    top: '0px !important',
  },
  '.MuiIconButton-root:hover': { background: 'rgba(0,0,0,.2) !important' },
  '.leftarrow': { right: '0px !important' },
  '.rightarrow': { left: '0px !important' },
  '.control-dots': {
    right: '10px',
    width: 'auto !important',
    bottom: '5px !important',
    [theme.breakpoints.down('sm')]: {
      bottom: '6px !important',
      margin: '0px !important',
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  '.slider': {
    gap: '0px',
    width: '100% !important',
  },
  '.slide': {
    // paddingRight: '8px',
    borderRadius: '12px',
    img: {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
      borderRadius: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '8px',
    },
  },

  '.slide:not(.selected)': { transform: 'none !important' },

  '.dot': {
    background: '#B5B5B5 !important',
    opacity: '1 !important',
    boxShadow: 'none !important',
    margin: '0px 4px !important',
    [theme.breakpoints.down('sm')]: {
      width: '4px !important',
      height: '4px !important',
    },
  },
  '.dot.selected': { background: '#FFBC45 !important' },
  '.slide.selected': {
    opacity: '1 !important',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 8px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 8px',
    },
  },
  '.carousel-status': { display: 'none' },
}));

const BannerTitle = styled(Box)(({ theme }) => ({
  fontWeight: '700',
  fontSize: '24px',
  color: '#fff',
  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '13px',
    whiteSpace: 'nowrap',
  },
}));

const BannerResultSlider = styled(Stack)(({ theme }) => ({
  // background: 'rgba(221, 99, 26, 1)!important',
  // background:'#ff0000',
  '.slidertable': {
    background: '#fff',
    borderRadius: '8px',
    '.flag_col': {
      width: '90px',
      [theme.breakpoints.down('sm')]: {
        width: '60px',
      },
      span: {
        width: '40px',
        float: 'left',
        [theme.breakpoints.down('sm')]: {
          width: '25px',
        },
      },
      img: {
        verticalAlign: 'middle',
        borderRadius: '0px',
        height: '18px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
          height: '8px',
        },
      },
    },
    'tr th': {
      background: '#1c2335',
      color: '#fff',
      fontSize: '14px',
      fontWeight: '800',
      padding: '3px 10px',
      textAlign: 'left',
      position: 'sticky',
      top: ' 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
        padding: '3px 5px',
      },
      '.flag_col': {
        img: {
          width: 'auto',
          borderRadius: '0',
          height: '18px',
          float: 'left',
          marginTop: '4px',
        },
      },
    },
    'tr td ': {
      fontSize: '14px',
      color: '#072d1a',
      padding: '0 10px',
      fontWeight: '600',
      textAlign: 'left',
      borderBottom: '1px solid #dbe6e2',

      height: '28px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
        padding: '2px 5px',
        height: '18px',
      },
    },
    'tr:last-child td  ': { borderBottom: '0px' },
  },
}));

const BannerTitleText = styled(Stack)(({ theme }) => ({
  pt: 0,
  background: alpha(theme.palette.grey[500], 1),
  display: 'none',
  borderRadius: '12px',
  padding: '16px 16px 16px 20px',
  textAlign: 'left',
  position: 'absolute',
  left: '0px',
  bottom: '0px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '8px',
    padding: '6px',
    left: '8px',
    width: 'calc(100% - 16px)',
  },
  [theme.breakpoints.down('md')]: {
    borderRadius: '8px',
    padding: '6px',
    left: '8px',
    width: 'calc(100% - 16px)',
  },
}));

function SampleNextArrow(props: any) {
  const theme = useTheme();
  const { onClickHandler } = props;

  return (
    <IconButton
      onClick={onClickHandler}
      className='leftarrow'
      sx={{
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 50px)',
        cursor: 'pointer',
        borderRadius: '0px',
        // bgcolor: `${theme.palette.background.paper} !important`,
        bgcolor: '#d4e8e126',
        width: { xs: '32px !important', xl: '32px !important' },
        height: { xs: '100px !important', xl: '100px !important' },
        boxShadow: '0px 24px 38px rgba(9, 15, 37, 0.07)',
        '&.hover': {
          bgcolor: '#d4e8e1',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          transform: 'scale(9)',
        },
        svg: {
          height: { md: 20, lg: 40, xl: '40px' },
          width: { md: 20, lg: 40, xl: '40px' },
        },
        right: { xs: '10px', md: '10px', lg: '10px', xl: '10px' },
      }}
      aria-label='button'
    >
      <IconChevronRight fontSize={25} color={theme.palette.grey[900]} />
    </IconButton>
  );
}

function SamplePrevArrow(props: any) {
  const theme = useTheme();
  const { onClickHandler } = props;

  return (
    <IconButton
      onClick={onClickHandler}
      className='rightarrow'
      sx={{
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 50px)',
        cursor: 'pointer',
        borderRadius: '0px',
        // bgcolor: `${theme.palette.background.paper} !important`,
        bgcolor: '#d4e8e126',
        width: { xs: '32px !important', xl: '32px !important' },
        height: { xs: '100px !important', xl: '100px !important' },
        boxShadow: '0px 24px 38px rgba(9, 15, 37, 0.07)',
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          transform: 'scale(9)',
        },
        svg: {
          height: { md: 20, lg: 40, xl: '40px' },
          width: { md: 20, lg: 40, xl: '40px' },
        },
        left: { xs: '10px', md: '10px', lg: '10px', xl: '10px' },
      }}
      aria-label='button'
    >
      <IconChevronLeft fontSize={25} color={theme.palette.grey[900]} />
    </IconButton>
  );
}

interface ItemProps {
  title: string;
  caption?: string;
  image: string;
}

const Items = ({ title, caption, image }: ItemProps) => {
  return (
    <>
      <Images
        src={image}
        alt='dashboard'
        className='444545'
        sx={{
          //   width: { xs: '100%', xl: 743 },
          objectFit: 'cover',
          direction: 'initial',
          borderRadius: '8px',
          backgroundPosition: 'bottom',
        }}
      />
      <BannerTitleText spacing={0}>
        <Stack
          direction='row'
          sx={{ textDecoration: 'none', textAlign: 'center' }}
        >
          <BannerTitle sx={{}}>{title}</BannerTitle>
        </Stack>
      </BannerTitleText>
    </>
  );
};

interface childProps {
  eventModelInfo: any;
}
const StreamResultBanner = (props: childProps) => {
  const { eventModelInfo } = props;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const downMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Grid spacing={{ xs: 3, sm: 3 }} container>
        <Grid item md={12} sm={12} xs={12}>
          <CarouselSlider
            className='preBuildDashBoard-slider'
            sx={{
              direction: 'initial',
              '.slider': {
                height: { xs: 'auto' },
                '& .slide:not(.selected)': {
                  transformOrigin: 'center !important',
                },
              },
            }}
          >
            <Grid item md={12} sm={12} xs={12}>
              {loading ? (
                <>
                  
                  <Skeleton
                    variant='rectangular'
                    sx={{
                      mt: '0px',
                      mb: '5px',
                      // background: theme.palette.secondary.main ,
                      height: '245px',
                      [theme.breakpoints.down('sm')]: {
                        height: '214px',
                        borderRadius: '8px',
                      },
                    }}
                  />
                </>
              ) : (
                <BannerResultSlider
                  sx={{
                    // height: '245px',
                    borderRadius: '8px',
                    [theme.breakpoints.down('sm')]: {
                      // height: '145px',
                    },
                  }}
                >
                  <Stack
                    sx={{
                      width: '100% !important',
                      height: 'auto !important',
                      borderRadius: '5px',
                      display:'flex', alignItems:'center',
                     
                    }}
                    
                  >
                    <img
                    style={{ height: '260px', position: 'absolute', top: '30px' }}
             src='https://www.northalley.com/projects/arionplay/animation.gif'
              alt=''
            />
                    <Stack sx={{display:'flex', padding:'20px', borderRadius:'30px', alignItems:'center',     background: 'rgba(0, 0, 0, .3)', justifyContent:'center', width:'300px',     position: 'absolute',
    bottom: '40px', }}>
                    <Stack>
                      <Typography sx={{color:'#fff !important', fontSize:'20px !important' , fontWeight:'600',  paddingBottom:'0px !important' }}>{
                        eventModelInfo.venueName
                      } </Typography>
                      <Typography sx={{color:'#fff !important', fontSize:'20px !important' , fontWeight:'600',  paddingBottom:'0px !important' }}>
                    {eventModelInfo
                    ? ` ${eventModelInfo.name.replace(
                        'Race',
                        'Event'
                      )}`
                    : 'No active event'}
                    </Typography>
                    </Stack>
                    <Stack>
                    <Stack sx={{alignItems:'center',}}
                                  
                                  className='closebetview'
                                  
                                >
                                  <Typography sx={{color:'#fff !important', fontSize:'20px !important' , fontWeight:'600', paddingBottom:'0px !important' }}>{
                                        eventModelInfo.eventStatus === 'FINAL'
                                          ? eventModelInfo.eventResult ===
                                            'WINNER-1'
                                            ? 'Winner :  X'
                                            : 'Winner :  O'
                                          : ''
                                      } </Typography>
                                      <Typography sx={{color:'#fff !important', fontSize:'20px !important' , fontWeight:'600',  paddingBottom:'0px !important' }}>
                                  {eventModelInfo && eventModelInfo.odds
                                    ? `
                                      Closed at : X(${
                                        MathFloor(
                                          eventModelInfo.odds['WIN-1']
                                        ) || 0
                                      }) , O(${
                                        MathFloor(
                                          eventModelInfo.odds['WIN-2']
                                        ) || 0
                                      }) `
                                    : ''}
                                    </Typography>
                                </Stack>

                    </Stack>
                    </Stack>
                    
                  </Stack>
                </BannerResultSlider>
              )}
            </Grid>
          </CarouselSlider>
        </Grid>
      </Grid>
    </>
  );
};

export default StreamResultBanner;
