import { get } from '@/services/HttpService';

const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL;

const GAME_PROVIDERS: GameRulesDictionary<object> = {
  evo: {
    name: 'Evolution Games',
  },
  rtg: {
    name: 'Real Time Games',
  },
  we: {
    name: 'World Platinum Entertainment Games',
  },
  jili: {
    name: 'Jili Games',
  },
  fc: {
    name: 'FA CHAI Games',
  },
};

export default class GameService {
  public static fetchGameDetails = async (url: string) => {
    try {
      const response = await get(`${BASE_URL}${url}`);
      return { status: true, data: response };
    } catch (err) {
      return { status: false, error: err.error };
    }
  };

  public static getGamesData = async () => {
    const response = await get(`${BASE_URL}games/home`);
    return { status: true, data: response };
  };

  public static getBannerData = async () => {
    const response = await get(`${BASE_URL}racecalendar/activeraces`);
    return { status: true, data: response };
  };

  public static getGamesForOperator = async (operatorId: string) => {
    const response = await get(`${BASE_URL}games/${operatorId}/list`);
    return { status: true, data: response };
  };

  public static getGameInfo = (provider: string) => {
    return GAME_PROVIDERS[provider];
  };
}
