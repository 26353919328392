// material-ui
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

// third-party
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

// assets
import {
  IconChevronRight,
  IconChevronLeft,
  IconLink,
} from '@tabler/icons-react';
import SliderDark2 from '../../public/images/ResponsibleGaming.png';
import BannerSlider2 from '../../public/images/banner33.png';
import IND from '../../public/images/ind-flag.png';

// types
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import GameService from '@/services/GameService';
import { maxWidth } from 'styled-system';

// styles
const Images = styled('img')(({ theme }) => ({
  marginBottom: 0,
  backgroundSize: '100%',
  objectFit: 'cover',
  [theme.breakpoints.down('sm')]: {
    backgroundSize: '100%',
    objectFit: 'fill',
  },
}));

const CarouselSlider = styled(Box)(({ theme }) => ({
  '.carousel-slider': {
    height: '265px',
    [theme.breakpoints.down('sm')]: {
      height: '145px',
    },
  },
  '.MuiButtonBase-root': {
    background: 'rgba(0,0,0,.2)',
    height: '100% !important',
    top: '0px !important',
  },
  '.MuiIconButton-root:hover': { background: 'rgba(0,0,0,.2) !important' },
  '.leftarrow': { right: '0px !important' },
  '.rightarrow': { left: '0px !important' },
  '.carousel.carousel-slider': { overflow: 'inherit' },
  '.control-dots': {
    right: '10px',
    width: '100% !important',
    bottom: '-25px !important',
    margin: '0px !important',
    [theme.breakpoints.down('sm')]: {
      bottom: '-10px !important',
      margin: '0px !important',
      // display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      // display: 'none',
    },
  },
  '.slider': {
    gap: '0px',
    width: '100% !important',
  },
  '.slide': {
    paddingRight: '8px',
    borderRadius: '12px',
    img: {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
      borderRadius: '8px',
      [theme.breakpoints.down('sm')]: {
        objectFit: 'inherit',
      },
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '8px',
      paddingRight: '8px',
    },
  },

  '.slide:not(.selected)': { transform: 'none !important' },

  '.dot': {
    background: '#B5B5B5 !important',
    opacity: '1 !important',
    boxShadow: 'none !important',
    margin: '0px 4px !important',
    [theme.breakpoints.down('sm')]: {
      width: '6px !important',
      height: '6px !important',
    },
  },
  '.dot.selected': { background: '#FFBC45 !important' },
  '.slide.selected': {
    opacity: '1 !important',
    [theme.breakpoints.down('sm')]: {
      // padding: '0px 0px',
    },
    [theme.breakpoints.down('md')]: {
      // padding: '0px 0px',
    },
  },
  '.carousel-status': { display: 'none' },
}));

const BannerTitle = styled(Box)(({ theme }) => ({
  fontWeight: '700',
  fontSize: '24px',
  color: '#fff',
  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '13px',
    whiteSpace: 'nowrap',
  },
}));

const BannerSlider = styled(Stack)(({ theme }) => ({
  '.slidertable': {
    background: '#fff',
    '.flag_col': {
      width: '90px',
      [theme.breakpoints.down('sm')]: {
        width: '40px',
      },
      span: {
        width: '40px',
        float: 'left',
        [theme.breakpoints.down('sm')]: {
          width: '16px',
        },
      },
      img: {
        verticalAlign: 'middle',
        borderRadius: '0px',
        height: '18px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
          height: '8px',
        },
      },
    },
    '.venue_col': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '75px',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '75px',
      },
    },
    '.races_col': {
      [theme.breakpoints.down('sm')]: {
        width: '35px',
        textAlign: 'center',
      },
    },
    '.time_col': {
      [theme.breakpoints.down('sm')]: {
        width: '80px',
        whiteSpace: 'nowrap',
      },
    },

    'tr th': {
      background: '#1c2335',
      color: '#fff',
      fontSize: '14px',
      fontWeight: '800',
      padding: '3px 10px',
      textAlign: 'left',
      position: 'sticky',
      top: ' 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
        padding: '2px 2px',
        [theme.breakpoints.down('xs')]: {
          fontSize: '7px',
        },
      },

      '.flag_col': {
        img: {
          width: 'auto',
          borderRadius: '0',
          height: '18px',
          float: 'left',
          marginTop: '4px',
        },
      },
    },
    'tr td ': {
      fontSize: '14px',
      color: '#072d1a',
      padding: '0 10px',
      fontWeight: '500',
      textAlign: 'left',
      borderBottom: '1px solid #dbe6e2',
      height: '28px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '8px',
        padding: '0px 2px',
        height: '13px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '7px',
      },
    },
  },
}));

const BannerTitleText = styled(Stack)(({ theme }) => ({
  pt: 0,
  background: alpha(theme.palette.grey[500], 1),
  display: 'none',
  borderRadius: '12px',
  padding: '16px 16px 16px 20px',
  textAlign: 'left',
  position: 'absolute',
  left: '0px',
  bottom: '0px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '8px',
    padding: '6px',
    left: '8px',
    width: 'calc(100% - 16px)',
  },
  [theme.breakpoints.down('md')]: {
    borderRadius: '8px',
    padding: '6px',
    left: '8px',
    width: 'calc(100% - 16px)',
  },
}));

function SampleNextArrow(props: any) {
  const theme = useTheme();
  const { onClickHandler } = props;

  return (
    <IconButton
      onClick={onClickHandler}
      className='leftarrow'
      sx={{
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 50px)',
        cursor: 'pointer',
        borderRadius: '0px',
        // bgcolor: `${theme.palette.background.paper} !important`,
        bgcolor: '#d4e8e126',
        width: { xs: '32px !important', xl: '32px !important' },
        height: { xs: '100px !important', xl: '100px !important' },
        boxShadow: '0px 24px 38px rgba(9, 15, 37, 0.07)',
        '&.hover': {
          bgcolor: '#d4e8e1',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          transform: 'scale(9)',
        },
        svg: {
          height: { md: 20, lg: 40, xl: '40px' },
          width: { md: 20, lg: 40, xl: '40px' },
        },
        right: { xs: '10px', md: '10px', lg: '10px', xl: '10px' },
      }}
      aria-label='button'
    >
      <IconChevronRight fontSize={25} color={theme.palette.grey[900]} />
    </IconButton>
  );
}

function SamplePrevArrow(props: any) {
  const theme = useTheme();
  const { onClickHandler } = props;

  return (
    <IconButton
      onClick={onClickHandler}
      className='rightarrow'
      sx={{
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 50px)',
        cursor: 'pointer',
        borderRadius: '0px',
        // bgcolor: `${theme.palette.background.paper} !important`,
        bgcolor: '#d4e8e126',
        width: { xs: '32px !important', xl: '32px !important' },
        height: { xs: '100px !important', xl: '100px !important' },
        boxShadow: '0px 24px 38px rgba(9, 15, 37, 0.07)',
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          transform: 'scale(9)',
        },
        svg: {
          height: { md: 20, lg: 40, xl: '40px' },
          width: { md: 20, lg: 40, xl: '40px' },
        },
        left: { xs: '10px', md: '10px', lg: '10px', xl: '10px' },
      }}
      aria-label='button'
    >
      <IconChevronLeft fontSize={25} color={theme.palette.grey[900]} />
    </IconButton>
  );
}

interface ItemProps {
  title: string;
  caption?: string;
  image: string;
}

const Items = ({ title, caption, image }: ItemProps) => {
  return (
    <>
      <Images
        src={image}
        alt='dashboard'
        className='444545'
        sx={{
          //   width: { xs: '100%', xl: 743 },
          objectFit: 'cover',
          direction: 'initial',
          borderRadius: '8px',
          backgroundPosition: 'bottom',
        }}
      />
      <BannerTitleText spacing={0}>
        <Stack
          direction='row'
          sx={{ textDecoration: 'none', textAlign: 'center' }}
        >
          <BannerTitle sx={{}}>{title}</BannerTitle>
        </Stack>
      </BannerTitleText>
    </>
  );
};

const SliderBanner = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [bannerList, setBannerList] = useState([]);
  const downMD = useMediaQuery(theme.breakpoints.down('md'));
  // alert(downMD);
  useEffect(() => {
    // Simulate an API call or data fetching
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the timeout as needed

    return () => clearTimeout(timer);
    // const downMD = useMediaQuery(theme.breakpoints.down('md'));
  }, []);

  const getBannerInfo = async () => {
    const response = await GameService.getBannerData();
    if (response.data && response.data.length > 0) {
      // Get the current date in YYYY-MM-DD format
      const currentDate = new Date().toISOString().split('T')[0];

      // Filter the data for the current date
      const filteredData = response.data.filter(
        (item: any) => item.raceDate === currentDate
      );

      // Sort the filtered data by displayOrder
      const sortedData = filteredData.sort((a: any, b: any) => {
        return Number(a.displayOrder) - Number(b.displayOrder);
      });

      // Set the filtered and sorted data to the state
      setBannerList(sortedData);
    }
  };

  useEffect(() => {
    getBannerInfo();
  }, []);

  return (
    <>
      <Grid spacing={{ xs: 3, sm: 3 }} container className='343434'>
        <Grid item md={12} sm={12} xs={12}>
          <CarouselSlider
            className='preBuildDashBoard-slider'
            sx={{
              direction: 'initial',
              '.slider': {
                height: { xs: 'auto' },
                '& .slide:not(.selected)': {
                  transformOrigin: 'center !important',
                },
              },
            }}
          >
            <Grid item md={12} sm={12} xs={12}>
              {loading ? (
                <>
                  <Skeleton
                    variant='rectangular'
                    sx={{
                      mt: '0px',
                      mb: '5px',
                      // background: theme.palette.secondary.main ,
                      height: '265px',
                      [theme.breakpoints.down('sm')]: {
                        height: '214px',
                        borderRadius: '8px',
                      },
                    }}
                  />
                </>
              ) : (
                <Carousel
                  selectedItem={0}
                  showThumbs={false}
                  centerMode={true}
                  centerSlidePercentage={downMD ? 80 : 50}
                  showArrows={false}
                  emulateTouch={true}
                  swipeable={true}
                  // autoPlay={true}
                  // interval={3000}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <SamplePrevArrow
                        onClickHandler={onClickHandler}
                        hasPrev={hasPrev}
                        label={label}
                      />
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <SampleNextArrow
                        onClickHandler={onClickHandler}
                        hasNext={hasNext}
                        label={label}
                      />
                    )
                  }
                >
                  <BannerSlider
                    sx={{
                      background: '#DD631A',
                      height: '265px',
                      borderRadius: '8px',
                      [theme.breakpoints.down('sm')]: {
                        height: '135px',
                      },
                    }}
                  >
                    <Stack>
                      <Typography
                        variant='h5'
                        sx={{
                          color: '#fff',
                          textAlign: 'left',
                          paddingLeft: '10px',
                          fontSize: '16px',
                          fontWeight: '800',
                          paddingTop: '5px',
                          [theme.breakpoints.down('sm')]: {
                            fontSize: '12px',
                            fontWeight: '800',
                          },
                        }}
                      >
                        RACE SCHEDULE
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        margin: '5px',
                        width: 'calc(100% - 10px) !important',
                        height: 'auto !important',
                        maxHeight: '199px',
                        overflow: 'hidden',
                        borderRadius: '5px',
                        scrollbarColor: '#aaa #ddd',
                        scrollbarWidth: 'thin',
                      }}
                    >
                      <table
                        className='slidertable'
                        style={{ color: '#000' }}
                        cellPadding={0}
                        cellSpacing={0}
                        border={0}
                      >
                        <thead>
                          <tr>
                            <th className='venue_col'>VENUE</th>
                            <th className='flag_col'></th>
                            <th className='races_col'>RACES</th>
                            <th className='time_col'>DATE & TIME</th>
                          </tr>
                        </thead>
                        {bannerList.length > 0 ? (
                          <tbody>
                            {bannerList &&
                              bannerList.map((v: any) => (
                                <tr>
                                  <td className='venue_col'>{v.venueName}</td>
                                  <td className='flag_col'>
                                    <span>{v.countryCode}</span>
                                    <img
                                      style={{}}
                                      src={`https://static-web.fra1.cdn.digitaloceanspaces.com/flags/${v.imagePath}`}
                                      alt=''
                                    />
                                  </td>
                                  <td className='races_col'>{v.totalRaces}</td>
                                  <td className='time_col'>
                                    {v.raceDate} {v.startTime}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        ) : (
                          <tr>
                            <td colSpan={4} style={{ textAlign: 'center' }}>
                              NO RACE SCHEDULE
                            </td>
                          </tr>
                        )}
                      </table>
                    </Stack>
                  </BannerSlider>
                  <Stack
                    sx={{
                      background: '#26334d',
                      height: '265px',
                      borderRadius: '8px',
                      [theme.breakpoints.down('sm')]: {
                        height: '135px',
                      },
                    }}
                  >
                    <img src={BannerSlider2} alt='' />
                  </Stack>
                  <Stack
                    sx={{
                      background: '#26334d',
                      height: '265px',
                      borderRadius: '8px',
                      [theme.breakpoints.down('sm')]: {
                        height: '135px',
                      },
                    }}
                  >
                    <img src={SliderDark2} alt='' />
                  </Stack>
                </Carousel>
              )}
            </Grid>
          </CarouselSlider>
        </Grid>
      </Grid>
    </>
  );
};

export default SliderBanner;
