import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, styled } from '@mui/material';
import BorderCard from '@/components/BorderCard';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

const GameCardImageWrapperDiv = styled('div')(({ theme }) => ({
  border: '1px solid #000',
  overflow: 'hidden',
  borderRadius: 10,
}));

const GameNameDiv = styled('div')(({ theme }) => ({
  color: '#A3ADC2',
  fontSize: 12,
  fontFamily: 'google_sansmedium, sans-serif',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: 10,
}));

const GameCategoryDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -1,
  right: -1,
  padding: '1px 10px',
  fontFamily: 'google_sansmedium, sans-serif',
  fontSize: 10,
  zIndex: 1,
  color: '#ffffff',
  borderRadius: '0px 0px 0px 8px',
  borderBottom: '1px solid #384766',
  borderLeft: '1px solid #384766',
  background: 'linear-gradient(180deg, #182740 0%, #02173A 100%)',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '81px',
  textOverflow: 'ellipsis',

  '@media (max-width: 600px)': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '84px',
    textOverflow: 'ellipsis',
  },
}));

export default function GameCard({ detail }: { detail: any }) {
  return (
    <BorderCard>
      <CardActionArea
        component={detail.externalurl?.startsWith('http') ? 'a' : Link}
        href={
          detail.externalurl?.startsWith('http')
            ? detail.externalurl
            : undefined
        }
        to={
          detail.externalurl === '' || detail.externalurl === undefined
            ? `/game/provider/${detail.id}`
            : detail.externalurl.startsWith('/')
            ? detail.externalurl
            : `/${detail.externalurl}`
        }
        target={detail.externalurl?.startsWith('http') ? '_blank' : undefined}
        rel={
          detail.externalurl?.startsWith('http')
            ? 'noopener noreferrer'
            : undefined
        }
      >
        <GameCardImageWrapperDiv>
          <CardMedia
            component='img'
            loading='lazy'
            alt={detail.title}
            image={detail.img}
          />
          <GameNameDiv>{detail.title}</GameNameDiv>
        </GameCardImageWrapperDiv>
      </CardActionArea>
    </BorderCard>
  );
}
