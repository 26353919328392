import * as React from 'react';
// Lib
import ReactApexChart from 'react-apexcharts';
// Mui
import { useTheme, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
// import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// Api
import { EventRunnerType } from '../api/RaceCardApi';
// utils
import { fNumber } from '../utils/formatNumber';
import StreamSliderBanner from './StreamBannerBoard';
import { Stack } from '@mui/material';
// SVG
// import { GreenDotSVG } from '@/shared/svg';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  width: 300,
  [theme.breakpoints.up('md')]: {
    width: 720,
  },
}));

type Props = {
  anchorEl: null | HTMLElement;
  runners: null | undefined | Array<EventRunnerType>;
  // handleClick: (event: React.MouseEvent<HTMLElement) => void,
  handleClose: () => void;
};

const BannerSlider = styled(Stack)(({ theme }) => ({
  background: 'rgba(221, 99, 26, .8)!important',
  padding: '20px',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
  '.red': {
    // background:
    //   'linear-gradient(90deg,#be1c31,#ec3f56 22.4%,#ec3f56 77.08%,#be1c31)',
    width: '20px',
    // border:'2px solid #be1c31',
    height: '20px',
    float: 'left',
    borderRadius: '0px',
    marginTop: '2px',
    fontSize: '18px',
    marginRight: '5px',
    color: '#be1c31',
    fontWeight: '800',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: '-3px',
    [theme.breakpoints.down('sm')]: {
      width: '10px',
      height: '10px',
    },
  },
  '.blue': {
    // background:
    //   'linear-gradient(90deg,#031df8,#3046ff 22.4%,#3046ff 77.08%,#031df8)',
    width: '20px',
    height: '20px',
    float: 'left',
    borderRadius: '0px',
    marginTop: '2px',
    fontSize: '18px',
    marginRight: '5px',
    color: '#031df8',
    fontWeight: '800',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: '-3px',
    // border:'2px solid #031df8',
    [theme.breakpoints.down('sm')]: {
      width: '10px',
      height: '10px',
    },
  },
  '.slidertable': {
    background: 'rgba(255, 255, 255, 0.6)',
    '.flag_col': {
      width: '90px',
      [theme.breakpoints.down('sm')]: {
        width: '60px',
      },
      span: {
        width: '40px',
        float: 'left',
        [theme.breakpoints.down('sm')]: {
          width: '25px',
        },
      },
      img: {
        verticalAlign: 'middle',
        borderRadius: '0px',
        height: '18px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
          height: '8px',
        },
      },
    },
    'tr th': {
      background: '#1c2335',
      color: '#fff',
      fontSize: '14px',
      fontWeight: '800',
      padding: '3px 10px',
      textAlign: 'left',
      position: 'sticky',
      top: ' 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
        padding: '3px 5px',
      },
      '.flag_col': {
        img: {
          width: 'auto',
          borderRadius: '0',
          height: '18px',
          float: 'left',
          marginTop: '4px',
        },
      },
    },
    'tr td ': {
      fontSize: '14px',
      color: '#072d1a',
      padding: '0 10px',
      fontWeight: '600',
      textAlign: 'left',
      borderBottom: '1px solid #dbe6e2',
      height: '28px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
        height: '20px',
      },
    },
  },
}));

export default function SportEventsMenu({
  anchorEl,
  runners,
  handleClose,
}: Props) {
  const open = Boolean(anchorEl);
  const theme = useTheme();
  // request = axios.get(url, config);

  const chartOptions = (mainColor: string) => ({
    colors: [mainColor],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: '78%', borderRadius: 2 } },
    tooltip: {
      x: { show: true },
      y: {
        formatter: (seriesName: number | string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: number | string) => '',
        },
      },
      marker: { show: false },
    },
  });

  return (
    <Popover
      aria-labelledby='race-cards-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      marginThreshold={48}
      // BackdropProps={{ invisible: false }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          border: '1px solid #FFFFFF69',
          padding: 1,
          mt: -1,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            borderBottom: '1px solid #FFFFFF69',
            borderRight: '1px solid #FFFFFF69',
            display: 'block',
            position: 'absolute',
            bottom: -1,
            right: 'calc(50% - 5px)',
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <BannerSlider
        sx={{
          // height: '245px',
          borderRadius: '8px',
          [theme.breakpoints.down('sm')]: {
            // height: '145px',
          },
        }}
      >
        <Stack
          sx={{
            // margin: '5px',
            width: '100% !important',
            height: 'auto !important',
            // maxHeight: '199px',
            // overflow: 'hidden',
            borderRadius: '5px',
            // scrollbarColor: '#aaa #ddd',
            // scrollbarWidth: 'thin',
          }}
        >
          <table
            className='slidertable'
            style={{ color: '#000' }}
            cellPadding={0}
            cellSpacing={0}
            border={0}
          >
            <thead>
              <tr>
                <th className='races_col'>HORSE NAME</th>
                <th className='races_col'>AGE / COLOR & SEX</th>
                <th className='races_col'>RTG</th>
                <th className='races_col'>WT.(KG)</th>
              </tr>
            </thead>
            <tbody>
              {runners &&
                runners.map((v: any, i: number) => (
                  <tr key={i}>
                    <td className='races_col'>
                      <span className={i % 2 === 0 ? 'red' : 'blue'}>
                        {i % 2 === 0 ? 'x' : 'o'}
                      </span>
                      {v.id}. {v.Line1} ({v.drawNumber ?? i + 1 ?? '-'})
                    </td>
                    <td className='races_col'>{v.ageColorSex ?? '-'}</td>
                    <td className='races_col'>{v.rating ?? '-'}</td>
                    <td className='races_col'>{v.weight ?? '-'}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Stack>
      </BannerSlider>
    </Popover>
  );
}
