import React from 'react';
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from '../../../components/Custom/mainstyle';
import AccordionSection from '../../../components/Custom/AccordionSection';

import PageHead from '../components/PageHead';

const listDetails = [
  {
    title: 'What is XO?',
    desc: `
    <ol>
    <li>XO, or Odd-Even, is a betting pool that gives bettors two (2) options when they place a bet. In horse racing, each horse is assigned a saddle number.</li>
    <li>To select X (or Odd):</li>
    <li>Select the runner with the odd saddle number as the first place winner in a designated race. (1,3,5,7, …).</li>
    <li>To select O (or Even):</li>
    <li>Select the runner with the even  saddle number as the first place winner in a designated race. (2,4,6,8, …).</li>
    </ol>`,
  },
  {
    title: 'What is the betting system that XO uses?',
    desc: `
    <ol>
    <li>XO uses a pari-mutuel or totalizator betting system.</li>
    <li>Here, the odds change based on betting volume. This means that as long as the betting pool is still "Open" and there are people betting, the odds will keep changing.</li>
    <li>When the betting pool is "Closed", that's when Final Odds are determined.</li>
    </ol>`,
  },
  {
    title: 'How do I place a bet?',
    desc: `
    <ol>
    <li><b>Step 1 : </b>  Log in to your ArionPlay account using your credentials.</li>
    <li><b>Step 2 : </b> Choose the specific event or game you are interested in betting on.</li>
    <li><b>Step 3 : </b> Enter the amount you want to wager. Select "X" or "O".</li>
    <li><b>Step 4 : </b> Click on the "Confirm" or "Place a Bet" button to finalize your bet.</li>
    </ol>`,
  },
];

const FaqSportsbetsEnquiries: React.FC = () => {
  return (
    <StyledInfoBoxRight>
      <PageHead title='Betting Enquiries' />
      <AccordionSection list={listDetails} />
    </StyledInfoBoxRight>
  );
};

export default FaqSportsbetsEnquiries;
