import React, { useEffect, useState } from 'react';
import {
  StyledVideoContainer,
  StyledVideoView,
  StyledVideoViewUpdate,
} from './style';
import Iframe from '@/components/iframe';
import VideoJsPlayer from '@/components/player';
import RacingService from '@/services/RacingService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { setRaceStreamData } from '@/redux/slices/racecard';
import AuthService from '@/services/AuthService';
import { setSelectedUserWallet } from '@/redux/slices/wallet';
import GameService from '@/services/GameService';
import { Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import StreamSliderBanner from '@/components/StreamBannerBoard';
import {
  alignItems,
  border,
  color,
  display,
  fontWeight,
  justifyContent,
} from 'styled-system';
import StreamResultBanner from '@/components/StreamResultBoard';
import { useParams } from 'react-router-dom';
const BannerSliderResult = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  // background: 'rgba(221, 99, 26, 1)!important',
  background: 'rgba(84, 214, 44, 0.7)',
  padding: '20px',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
}));

const BannerSlider = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  background: 'rgba(221, 99, 26, .8)!important',
  padding: '20px',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
  '.red': {
    // background:
    //   'linear-gradient(90deg,#be1c31,#ec3f56 22.4%,#ec3f56 77.08%,#be1c31)',
    width: '20px',
    // border:'2px solid #be1c31',
    height: '20px',
    float: 'left',
    borderRadius: '0px',
    marginTop: '2px',
    fontSize: '18px',
    marginRight: '5px',
    color: '#be1c31',
    fontWeight: '800',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: '-3px',
    [theme.breakpoints.down('sm')]: {
      width: '10px',
      height: '10px',
    },
  },
  '.blue': {
    // background:
    //   'linear-gradient(90deg,#031df8,#3046ff 22.4%,#3046ff 77.08%,#031df8)',
    width: '20px',
    height: '20px',
    float: 'left',
    borderRadius: '0px',
    marginTop: '2px',
    fontSize: '18px',
    marginRight: '5px',
    color: '#031df8',
    fontWeight: '800',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: '-3px',
    // border:'2px solid #031df8',
    [theme.breakpoints.down('sm')]: {
      width: '10px',
      height: '10px',
    },
  },
  '.slidertable': {
    background: 'rgba(255, 255, 255, 0.6)',
    '.flag_col': {
      width: '90px',
      [theme.breakpoints.down('sm')]: {
        width: '60px',
      },
      span: {
        width: '40px',
        float: 'left',
        [theme.breakpoints.down('sm')]: {
          width: '25px',
        },
      },
      img: {
        verticalAlign: 'middle',
        borderRadius: '0px',
        height: '18px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
          height: '8px',
        },
      },
    },
    'tr th': {
      background: '#1c2335',
      color: '#fff',
      fontSize: '14px',
      fontWeight: '800',
      padding: '3px 10px',
      textAlign: 'left',
      position: 'sticky',
      top: ' 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
        padding: '3px 5px',
      },
      '.flag_col': {
        img: {
          width: 'auto',
          borderRadius: '0',
          height: '18px',
          float: 'left',
          marginTop: '4px',
        },
      },
    },
    'tr td ': {
      fontSize: '14px',
      color: '#072d1a',
      padding: '0 10px',
      fontWeight: '600',
      textAlign: 'left',
      borderBottom: '1px solid #dbe6e2',
      height: '28px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
        height: '20px',
      },
    },
  },
}));

interface childProps {
  venueId: string;
  status?: string;
  stopBetFlag?: string;
  runners?: any;
  eventModelInfo?: any;
}
const RaceVideo = (props: childProps) => {
  const theme = useTheme();
  const { module } = useParams();
  const { venueId, status, stopBetFlag, runners, eventModelInfo } = props;
  // debugger;
  const [bannerList, setBannerList] = useState([]);
  const dispatch = useAppDispatch();
  const { raceStreamData } = useAppSelector(state => state.raceinfo);
  const [streamObj, setStreamObj] = useState<IStream>({
    message: '',
    iframeFlag: '',
    player: '',
    streamUrl: '',
    isPaid: false,
  });
  const [player, setPlayer] = useState({
    fill: true,
    fluid: true,
    autoplay: true,
    controls: true,
    preload: 'metadata',
    sources: [
      {
        src: 'https://v3.szjal.cn/20191101/PZzNpqB1/index.m3u8',
        type: 'application/x-mpegURL',
      },
    ],
  });
  const [walletBal, setWalletBal] = useState(0);
  const getStreamDetails = async () => {
    setStreamObj({
      message: '',
      iframeFlag: '',
      player: '',
      streamUrl: '',
      isPaid: false,
    });
    const response = await RacingService.getStreamData(venueId);
    if (response.body && response.body.data) {
      const data = response.body.data;
      if (data.provider === 'SIS') {
        const result = await RacingService.getSISStreamUrl(data.streamId);
        data.iframeFlag = 'N';
        if (result.body.data.error !== undefined && result.body.data.error) {
          data.message = result.body.data.error;
        } else {
          data.message = '';
          data.iframeFlag = 'Y';
          data.streamUrl = result.body.data.phenixEmbedUrl;
        }
      }
      dispatch(setRaceStreamData(data));
    }
  };
  useEffect(() => {
    if (venueId) getStreamDetails();
  }, [venueId]);

  const getUserWalletInfo = async () => {
    const response: any = await AuthService.getMyWallets();
    if (response.status && response.data.length > 0) {
      setWalletBal(response.data[0]?.balance);
      dispatch(setSelectedUserWallet(response.data[0]?.balance));
    }
  };

  useEffect(() => {
    getUserWalletInfo();
  }, []);

  useEffect(() => {
    if (raceStreamData) {
      if (
        raceStreamData.iframeFlag === 'N' &&
        raceStreamData.player === 'videojs'
      ) {
        const source = {
          sources: [
            { src: raceStreamData.streamUrl, type: 'application/x-mpegURL' },
          ],
        };
        setPlayer({ ...player, ...source });
        dispatch(setRaceStreamData(raceStreamData));
      }
      setStreamObj(raceStreamData);
    }
  }, [raceStreamData]);

  useEffect(() => {
    setBannerList(runners);
  }, [runners]);

  return (
    <>
      <StyledVideoContainer>
        <StyledVideoView>
          {walletBal > 0 && (
            <div className='videoview' style={{ position: 'relative' }}>
              {streamObj.iframeFlag === 'Y' &&
              (streamObj.message === undefined || streamObj.message === '') ? (
                <>
                  <Iframe
                    venueCode={streamObj.venueCode}
                    streamUrl={streamObj.streamUrl}
                  />
                  {/* {stopBetFlag === 'N' &&
                    runners.length > 0 &&
                    module !== 'bk' && (
                      <>
                        <BannerSlider>
                          <StreamSliderBanner bannerList={bannerList} />
                        </BannerSlider>
                      </>
                    )} */}
                </>
              ) : streamObj.iframeFlag === 'N' &&
                streamObj.player === 'videojs' ? (
                <VideoJsPlayer {...player} />
              ) : streamObj.message !== '' ? (
                <div className='messageview'>
                  {streamObj.message}
                  {streamObj.message.toLowerCase() === 'thank you' &&
                    eventModelInfo.results !== null && (
                      <BannerSliderResult>
                        <StreamResultBanner eventModelInfo={eventModelInfo} />
                      </BannerSliderResult>
                    )}
                  {/* <p className='videovenunamerace'>{streamObj.venueCode}</p>{' '} */}
                </div>
              ) : streamObj.message === '' ? (
                <div className='messageview'>
                  THE LIVE STREAM WILL BE AVAILABLE SOON
                  <p className='videovenunamerace'>
                    {streamObj.venueCode}
                  </p>{' '}
                </div>
              ) : (
                <div className='messageview'>
                  RACE FINISHED{' '}
                  <p className='videovenunamerace'>{streamObj.venueCode}</p>{' '}
                </div>
              )}
            </div>
          )}
          {walletBal === 0 && (
            <div className='messageview'>
              PLEASE ADD CREDITS TO WALLET TO WATCH LIVE STREAM
              <p className='videovenunamerace'>{streamObj.venueCode}</p>{' '}
            </div>
          )}
        </StyledVideoView>
      </StyledVideoContainer>
    </>
  );
};

export default RaceVideo;
